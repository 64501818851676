import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'react-placeholder/lib/reactPlaceholder.css';
import ReactPlaceholder from 'react-placeholder';
import './providers-slider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';
SwiperCore.use([Autoplay]);
const windowWidth = window.innerWidth;
const awesomePlaceholder = (
  windowWidth > 499 ?
    <div style={{ width: '100%', height: '200px', display: 'flex', backgroundColor: 'white' }}>
      {[...Array(5)]?.map((item, index) => (
        <img style={{ width: '18%', marginRight: index === 4 ? '0px' : '25px', height: '100%', objectFit: 'cover', borderRadius: '8px' }} src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/472292a2-8cba-4ccf-8403-ded0c3d6d3ad/dagmud4-52cf93df-6141-4225-a3a9-59e159a8dac3.png/v1/fill/w_1024,h_1360,q_80,strp/hello_kitty_by_kittykun123_dagmud4-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTM2MCIsInBhdGgiOiJcL2ZcLzQ3MjI5MmEyLThjYmEtNGNjZi04NDAzLWRlZDBjM2Q2ZDNhZFwvZGFnbXVkNC01MmNmOTNkZi02MTQxLTQyMjUtYTNhOS01OWUxNTlhOGRhYzMucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.FzYjvvyT0qWO7HuE3AqV5BXnOiHtFxE4aRs89a5AlP4' />
        // <div style={{ width: '18%', marginRight: '25px', height: '100%', backgroundColor: 'gainsboro', backgroundImage: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/472292a2-8cba-4ccf-8403-ded0c3d6d3ad/dagmud4-52cf93df-6141-4225-a3a9-59e159a8dac3.png/v1/fill/w_1024,h_1360,q_80,strp/hello_kitty_by_kittykun123_dagmud4-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTM2MCIsInBhdGgiOiJcL2ZcLzQ3MjI5MmEyLThjYmEtNGNjZi04NDAzLWRlZDBjM2Q2ZDNhZFwvZGFnbXVkNC01MmNmOTNkZi02MTQxLTQyMjUtYTNhOS01OWUxNTlhOGRhYzMucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.FzYjvvyT0qWO7HuE3AqV5BXnOiHtFxE4aRs89a5AlP4' }}>
        //   {/* <RectShape color="gainsboro" /> */}
        //   {/* <TextBlock rows={6} color="gainsboro" /> */}
        // </div>
      ))}
    </div> : <div style={{ width: '100%', height: '280px', display: 'flex', backgroundColor: 'white', }}>
      <img style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/472292a2-8cba-4ccf-8403-ded0c3d6d3ad/dagmud4-52cf93df-6141-4225-a3a9-59e159a8dac3.png/v1/fill/w_1024,h_1360,q_80,strp/hello_kitty_by_kittykun123_dagmud4-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTM2MCIsInBhdGgiOiJcL2ZcLzQ3MjI5MmEyLThjYmEtNGNjZi04NDAzLWRlZDBjM2Q2ZDNhZFwvZGFnbXVkNC01MmNmOTNkZi02MTQxLTQyMjUtYTNhOS01OWUxNTlhOGRhYzMucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.FzYjvvyT0qWO7HuE3AqV5BXnOiHtFxE4aRs89a5AlP4' />
    </div>
);

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
export default function ProvidersCarousel({ data, loading }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);

  const setLoading = () => {
    setLoaded(true);
  }

  const goToGameDetails = (item) => {
    history.push(`/games-list/${item.provider_id}`);
  };
  //show default image
  const addDefaultSrc = (ev) => {
    ev.target.src = "'/images/kitty3.jpg';";
  };

  const getImageName = (icon) => {
    let arr = icon.split('/');
    let game_name = arr[arr.length - 1];
    return game_name;
  };

  return (
    <React.Fragment>
      <div className="provider-container">
        <div className="slider-container-title">
          <h5>
            <img src="/images/trend5.webp" alt="cazi-provider" className="gameProvider"></img>
            <span className="header-sp">Game Providers</span>
          </h5>
        </div>

        <Swiper
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={10}
          slidesPerGroup={1}
          observer={true}
          observeParents={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              if (
                swiper &&
                swiper.params &&
                swiper.params.navigation &&
                swiper.navigation
              ) {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            });
          }}
          breakpoints={{
            500: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
            1300: {
              slidesPerView: 8,
            },
            1920: {
              slidesPerView: 12,
            },
          }}
        >
          <div
            ref={navigationPrevRef}
            className="previous-custom"
            id="prev-custom-arrow"
          ></div>
          <div
            ref={navigationNextRef}
            className="next-custom"
            id="next-custom-arrow"
          ></div>

          {loading ? (
            <ReactPlaceholder ready={loading}>
              {awesomePlaceholder}
            </ReactPlaceholder>
          ) : (
            data.map((item, i) => {
              return (
                <SwiperSlide
                  key={i}
                  className="providers-carousel"
                  onClick={() => goToGameDetails(item)}
                >
                  <LazyLoadImage alt={item?.provider_name} onError={addDefaultSrc} onLoad={setLoading}
                    style={{ width: 'auto', height: '107px' }}
                    src={loaded === false ? "/images/kitty3.jpg" : `/images/kitty3.jpg/${getImageName(
                      item.icon
                    )}`} />
                </SwiperSlide>
              );
            })
          )}
        </Swiper>
      </div>
    </React.Fragment>
  );
}
