export const GET_EVOLUTION_GAMES = "GET_EVOLUTION_GAMES"
export const GET_GAME_PROVIDERS = "GET_GAME_PROVIDERS"
export const GET_LIVE_GAMES = "GET_LIVE_GAMES"
export const GET_LAST_WINNERS = "GET_LAST_WINNERS"
export const GET_GAME_SLOTS = "GET_GAME_SLOTS"
export const SET_PROVIDER = "SET_PROVIDER";

// export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
// export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";


export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_LANGUAGE = "SET_LANGUAGE";


//Meta mask type
export const LIVE_PRICE = "LIVE_PRICE";
export const SET_ADDRESS = "SET_ADDRESS";
export const DELETE_META_MASK_ADDRESS = "DELETE_META_MASK_ADDRESS";
export const SET_CONTRACT = "SET_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const USER_BALANCE = "USER_BALANCE";
export const UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE";
export const SET_CAZI_BALANCE = 'SET_CAZI_BALANCE';
export const SET_WALLET_PROVIDER = 'SET_WALLET_PROVIDER';
export const DELETE_WALLET_PROVIDER = 'DELETE_WALLET_PROVIDER';



export const SET_APPROVAL_FOR_LP = "SET_APPROVAL_FOR_LP";
export const SET_APPROVAL_FOR_CAZI = "SET_APPROVAL_FOR_CAZI";
export const SET_DECIMALS_FOR_LP = "SET_DECIMALS_FOR_LP";
export const SET_DECIMALS_FOR_CAZI = "SET_DECIMALS_FOR_CAZI";

export const DELETE_APPROVAL_FOR_LP = "DELETE_APPROVAL_FOR_LP";
export const DELETE_APPROVAL_FOR_CAZI = "DELETE_APPROVAL_FOR_CAZI";
export const DELETE_DECIMALS_FOR_LP = "DELETE_DECIMALS_FOR_LP";
export const DELETE_DECIMALS_FOR_CAZI = "DELETE_DECIMALS_FOR_CAZI";

export const SET_VALUES_FOR_CAZI_PRICE = "SET_VALUES_FOR_CAZI_PRICE";
export const SET_CIR_SUPP_FOR_LP = "SET_CIR_SUPP_FOR_LP";

export const SET_TOTAL_FARMS = "SET_TOTAL_FARMS";
export const SET_TOTAL_STAKES = "SET_TOTAL_STAKES";

export const SET_TRANSACTION_IN_PROGRESS = "SET_TRANSACTION_IN_PROGRESS";

//Sidebar type
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";